







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
  components: {
  },
  data: () => ({
    //
  })
})
export default class Admin extends Vue {
  dialog = false
  // @Prop({ default: '' }) private localNotes!: string
  get localDefData (): any {
    const defData = {
      owners: this.$store.state.tempNotesData,
      programs: this.$store.state.tempNotesData,
      progressionLevels: this.$store.state.tempNotesData,
      locations: this.$store.state.tempNotesData,
      // activityLocations: this.$store.state.tempNotesData,
      milestones: this.$store.state.tempNotesData,
      skills: this.$store.state.tempNotesData,
      stages: this.$store.state.tempNotesData,
      strands: this.$store.state.tempNotesData,
      activities: this.$store.state.tempNotesData,
      strandDevelopmentMilestones: this.$store.state.tempNotesData
    }
    // const defData = this.$store.state.systemData

    return JSON.stringify(this.$store.state.systemData)
    // return defData
  }

  get plannerData (): any {
    return JSON.stringify(this.$store.state.plannerData)
  }

  public loadDefaultData (): void {
    this.$store.dispatch('assembleAndCleanProgramJson')
    this.dialog = false
  }
}
